import React, { useState } from "react";
import jsPDF from "jspdf";

const Recette = () => {
  const [alimentBase, setAlimentBase] = useState("");
  const [typePlat, setTypePlat] = useState("Plat");
  const [ingredientOptionnel, setIngredientOptionnel] = useState("");
  const [region, setRegion] = useState("non spécifié");
  const [recette, setRecette] = useState(null);
  const [error, setError] = useState(null);

  const apiKey = "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"; // Remplace par ta clé API

  // Tableau d'exemples d'aliments pour chaque groupe
  const groupesAliments = {
    glucides: ["Riz", "Maïs", "Manioc", "Patate douce", "Bananes vertes à cuire", "Igname", "Millet", "Sorgho", "Orge", "Blé", "Avoine", "Fonio"],
    legumineuses: ["Haricot", "Pois du Cap", "Soja", "Pois chiches", "Lentilles", "Fèves", "Lupins", "Haricot rouge", "Haricot noir", "Pois cassés", "Haricot mungo"],
    graines: ["Arachides", "Graines de baobab", "Noix de coco", "Graines de sésame", "Noix de cajou", "Noisettes", "Noix du Brésil", "Noix de macadamia", "Pistaches", "Graines de lin", "Graines de tournesol", "Amandes"],
    produitsLaitiers: ["Fromage", "Yaourt", "Abobo", "Lait de vache", "Lait de chèvre", "Lait de brebis", "Lait concentré", "Lait fermenté", "Lait caillé", "Beurre", "Crème fraîche"],
    viandes: ["Poulet", "Poisson", "Bœuf", "Foie", "Crevettes", "Agneau", "Dinde", "Porc", "Canard", "Thon", "Sardine", "Crabe"],
    coquillages: ["Œufs de poule", "Œufs de canard", "Œufs de caille", "Œufs d'oie", "Œufs de pintade", "Œufs de dinde", "Œufs de cormoran"],
    legumesVerts: ["Épinards", "Feuilles de manioc", "Brède mafane", "Brocoli", "Anamambo", "Chou kale", "Laitue", "Chou vert", "Roquette", "Cresson", "Persil", "Coriandre", "Blette"],
    fruitsOrange: ["Papaye", "Mangue", "Carotte", "Citrouille", "Patate douce orange"],
    legumesDivers: ["Oignons", "Aubergine", "Champignons", "Radis", "Fenouil", "Chou-fleur", "Navet", "Artichaut", "Betterave", "Asperges", "Concombre"],
    fruitsRouges: ["Goyave", "Litchi", "Pastèque", "Fraise", "Framboise", "Cerise", "Grenade", "Tomate", "Raisins rouges", "Mûres", "Prune", "Myrtilles"]
  };

  // Fonction pour sélectionner aléatoirement 2 ou 3 aliments parmi les groupes
  const getRandomAliments = () => {
    const allAliments = [
      ...groupesAliments.glucides,
      ...groupesAliments.legumineuses,
      ...groupesAliments.graines,
      ...groupesAliments.produitsLaitiers,
      ...groupesAliments.viandes,
      ...groupesAliments.coquillages,
      ...groupesAliments.legumesVerts,
      ...groupesAliments.fruitsOrange,
      ...groupesAliments.legumesDivers,
      ...groupesAliments.fruitsRouges
    ];

    // Sélectionner 2 ou 3 aliments au hasard
    const selectedAliments = [];
    const numberOfAliments = Math.floor(Math.random() * 2) + 2; // Choisir 2 ou 3 aliments
    while (selectedAliments.length < numberOfAliments) {
      const randomAliment = allAliments[Math.floor(Math.random() * allAliments.length)];
      if (!selectedAliments.includes(randomAliment)) {
        selectedAliments.push(randomAliment);
      }
    }
    return selectedAliments.join(", ");
  };

  const fetchRecipe = async () => {
    setError(null);
    setRecette(null);

    const alimentBaseUsed = alimentBase || getRandomAliments(); // Si alimentBase est vide, on génère une sélection aléatoire

    const prompt = `
      Génère une recette de cuisine basée sur les critères suivants :
      - Aliment de base : ${alimentBaseUsed}
      - Type de plat : ${typePlat}
      - Région de Madagascar : ${region}

      Voici le contexte : 
      Promouvoir la diversification alimentaire à Madagascar, pour le grand public, de type classe moyenne, voire "pauvre" à Madagascar.

      Le but global est de réhausser le score de diversité alimentaire à Madagascar pour chaque petit ménage, et ce en proposant des menus variés et utilisant divers ingrédients les plus variés. 

      Dans l'idéal, le budget par personne pour chaque recette à concocter est entre 0,5$ et 1,5$ par personne. Chaque recette à concocter est pour 4 personnes. L'idée est de faire des menus simples, de préférence avec des ingrédients qui sont accessibles au grand public. 
      Voici les maîtres mots pour créer chaque recette :
      - diversité de groupe d'aliments dans chaque plat  ( selon le 10 groupes d'aliments ci-dessous pour une parfaite nutrition de la population)  
      - facilité de préparation 
      - bas coût, autant que possible
      - la santé, via, l'augmentation du score (de diversité) alimentaire.


      Selon  le tableau de départ ci-dessous qui comporte les 10 groupes d'aliments, et quelques exemples d'aliments de ces groupes d'où, autant que possible, il faudra puiser essentiellement les ingrédients de chaque recette.



      Respecte ces consignes :
      - Utilise, en priorité ou essentiellement les aliments du tableau suivant : 
      Groupe d'aliments
      Couleur
      Caractéristiques
      Atouts/Rapports
      Exemple aliments
      Glucides (Féculents et Céréales)
      Jaune
      Sources d’énergie, riches en glucides complexes.
      Apportent force et endurance au corps.
        Riz
        Maïs
        Manioc
        Patate douce
        Bananes vertes à cuire
        Igname
        Millet
        Sorgho
        Orge
        Blé
        Avoine
        Fonio

      Légumineuses (Protéines végétales)
      Marron, marron clair, noir
      Riches en protéines et fibres.
      Contribuent à la satiété et préviennent les carences.
        Haricot
        Pois du Cap (kabaro)
        Soja
        Pois chiches
        Lentilles
        Fèves
        Lupins
        Haricot rouge
        Haricot noir
        Pois cassés
        Haricot mungo

      Graines oléagineuses et fruits secs
      Marron, noir, blanc
      Riches en lipides, protéines, fibres et minéraux.
      Apportent de bonnes graisses, des protéines et des antioxydants.
        Arachides
        Graines de baobab
        Noix de coco
        Graines de sésame
        Noix de cajou
        Noisettes
        Noix du Brésil
        Noix de macadamia
        Pistaches
        Graines de lin
        Graines de tournesol
        Amandes

      Produits laitiers
      Blanc
      Source de calcium et potassium.
      Contribuent à la solidité des os et au bon fonctionnement musculaire.
        Fromage
        Yaourt
        Abobo (lait fermenté)
        Lait de vache
        Lait de chèvre
        Lait de brebis
        Lait concentré
        Lait fermenté (kéfir)
        Lait caillé
        Beurre
        Crème fraîche

      Viandes, poissons et fruits de mer
      Rouge, blanc
      Riches en protéines de haute qualité, fer, zinc et vitamine B12.
      Facilitent le développement musculaire et préviennent l’anémie.
        Poulet
        Poisson
        Bœuf
        Foie
        Crevettes
        Agneau
        Dinde
        Porc
        Canard
        Thon
        Sardine
        Crabe

      Coquillages (moules, huîtres)
      Œufs (de poule et volailles diverses)
      Blanc, Jaune
      Source de protéines et nutriments essentiels.
      Contiennent des protéines de haute qualité et des vitamines rares dans les végétaux.
        Œufs de poule
        Œufs de canard
        Œufs de caille
        Œufs d'oie
        Œufs de pintade
        Œufs de dinde
        Œufs de cormoran
      
      Légumes verts à feuilles (Brèdes et légumes-feuilles)
      Vert
      Riches en vitamines A, C, fer et acide folique.
      Contribuent à la santé oculaire, immunitaire et au renouvellement sanguin.
        Épinards
        Feuilles de manioc pilées (ravitoto)
        Brède mafane
        Brocoli
        Anamambo (moringa)
        Chou kale
        Laitue
        Chou vert
        Roquette
        Cresson
        Persil
        Coriandre
        Blette
      
      Fruits et légumes orange (sources de vitamines et minéraux)
      Orange
      Riches en vitamine A, folates, vitamine C.
      Contribuent à la croissance, à la vision et à l’immunité.
        Papaye
        Mangue
        Carotte
        Citrouille
        Patate douce orange
      
      Légumes divers (sources de vitamines et minéraux)
      Mauve, marron, blanc
      Riches en antioxydants et oligo-éléments.
      Contribuent à l’équilibre nutritionnel et préviennent les maladies chroniques.
        Oignons
        Aubergine
        Champignons
        Radis
        Fenouil
        Chou-fleur
        Navet
        Artichaut
        Betterave
        Asperges
        Concombre
      Fruits rouges et autres fruits riches en nutriments
      Rouge
      Riches en antioxydants, flavonoïdes et minéraux.
      Protègent contre le vieillissement cellulaire et les maladies cardiovasculaires.
        Goyave
        Litchi
        Pastèque
        Fraise
        Framboise
        Cerise
        Grenade
        Tomate
        Raisins rouges
        Mûres
        Prune
        Myrtilles


      - Ne pas proposer de recettes nécessitant l'utilisation d'un four (oven)
      - Budget maximum : 1,5$ par personne.
      - La recette doit être simple et, si possible, rapide à préparer.
      - Consigne supplémentaire : ${ingredientOptionnel || "aucun"}
      - Fournis uniquement un JSON sans texte explicatif, sous ce format précis :

      {
        "nom": "Nom de la recette",
        "groupe": "Groupe d'aliments principal",
        "type": "Type de plat",
        "ingredients": ["Ingrédient 1", "Ingrédient 2", "Ingrédient 3", "Ingrédient 4", "Ingrédient 5"],
        "preparation": ["Étape 1", "Étape 2", "Étape 3", "Étape 4", "Étape 5"],
        "apports": "Bienfaits nutritionnels",
        "duree": "Durée en minutes",
        "prix": "Prix en Ariary"
      }

      Ne fournis que le JSON, sans explication supplémentaire.
      Je répète bien : 
      Ne fournis que le JSON, sans explication supplémentaire.

    `;

    try {
      const response = await fetch("https://api.openai.com/v1/chat/completions", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiKey}`,
        },
        body: JSON.stringify({
          model: "gpt-3.5-turbo",
          messages: [
            { role: "system", content: "Tu es un chef cuisinier malgache et nutritionniste, et tu génères des recettes économiques et simples." },
            { role: "user", content: prompt }
          ],
          max_tokens: 300,
          temperature: 0.7,
        }),
      });

      const data = await response.json();

      if (!data.choices || data.choices.length === 0) {
        throw new Error("Réponse invalide de l'API OpenAI");
      }

      const recetteJSON = JSON.parse(data.choices[0].message.content.trim());
      setRecette(recetteJSON);
    } catch (error) {
      setError("Erreur lors de la génération de la recette.");
      console.error("Erreur API:", error);
    }
  };

  
  const generatePDF = () => {
    if (recette) {
      const doc = new jsPDF();
      doc.setFont("helvetica", "normal");
  
      // Définir les marges et l'espace entre les lignes
      const margeGauche = 10;
      let yPosition = 10;
      const espaceLigne = 10;
      const largeurMax = 180; // Limite de largeur avant retour à la ligne (en mm)
  
      // Ajouter le titre de la recette
      doc.text("Recette: " + recette.nom, margeGauche, yPosition);
      yPosition += espaceLigne;
  
      // Ajouter le type de plat
      doc.text("Type de plat: " + recette.type, margeGauche, yPosition);
      yPosition += espaceLigne;
  
      // Ajouter les ingrédients
      doc.text("Ingrédients: ", margeGauche, yPosition);
      yPosition += espaceLigne;
      recette.ingredients.forEach((ingredient, index) => {
        // Découpe automatique pour éviter les dépassements de largeur
        const lignesIngr = doc.splitTextToSize(ingredient, largeurMax);
        lignesIngr.forEach(ligne => {
          doc.text(ligne, margeGauche, yPosition);
          yPosition += espaceLigne;
        });
      });
  
      // Ajouter la préparation
      doc.text("Préparation: ", margeGauche, yPosition);
      yPosition += espaceLigne;
      recette.preparation.forEach((etape, index) => {
        // Découpe automatique pour éviter les dépassements de largeur
        const lignesEtape = doc.splitTextToSize(etape, largeurMax);
        lignesEtape.forEach(ligne => {
          doc.text(ligne, margeGauche, yPosition);
          yPosition += espaceLigne;
        });
      });
  
      // Ajouter les apports
      doc.text("Apports: " + recette.apports, margeGauche, yPosition);
      yPosition += espaceLigne;
  
      // Ajouter la durée et le prix
      doc.text("Durée: " + recette.duree + " minutes", margeGauche, yPosition);
      yPosition += espaceLigne;
      doc.text("Prix: " + recette.prix + " Ariary", margeGauche, yPosition);
      yPosition += espaceLigne;
  
      // Utiliser le nom du plat pour générer le nom du fichier PDF
      const nomPlat = recette.nom; // Assurez-vous que le nom de la recette est bien défini dans "recette.nom"
      const nomFichier = `${nomPlat.replace(/\s+/g, '_')}.pdf`; // Remplacer les espaces par des underscores
  
      // Sauvegarder le fichier PDF avec le nom personnalisé
      doc.save(nomFichier);
    } else {
      alert("Aucune recette générée pour le moment.");
    }
  };
  
  

  return (
    <div className="container mt-5">
      <h1 className="text-center mb-4">Générateur de Recette Malgache</h1>
      <div className="row g-3">
        <div className="col-md-6">
          <input
            type="text"
            placeholder="Aliments de base (facultatifs)"
            value={alimentBase}
            onChange={(e) => setAlimentBase(e.target.value)}
            className="form-control"
          />
        </div>
        <div className="col-md-6">
          <select value={typePlat} onChange={(e) => setTypePlat(e.target.value)} className="form-select">
            <option>Salade</option>
            <option>Accompagnement</option>
            <option>Plat</option>
            <option>Dessert</option>
            <option>Tartinade</option>
            <option>Petit-déjeuner</option>
            <option>Sauce</option>
            <option>Boisson</option>
          </select>
        </div>
        <div className="col-md-6">
          <input
            type="text"
            placeholder='Consigne supplémentaire (facultatif) - Exemple : "Propose un plat sucré"'
            value={ingredientOptionnel}
            onChange={(e) => setIngredientOptionnel(e.target.value)}
            className="form-control"
          />
        </div>
        <div className="col-md-6">
          <select value={region} onChange={(e) => setRegion(e.target.value)} className="form-select">
            <option>non spécifié</option>
            <option>sud ouest</option>
            <option>sud est</option>
            <option>nord est</option>
            <option>nord</option>
            <option>nord ouest</option>
            <option>ouest</option>
            <option>centre</option>
          </select>
        </div>
        <div className="col-12 text-center">
          <button onClick={fetchRecipe} className="btn btn-primary">Générer la recette</button>
        </div>
      </div>

      {error && <p className="text-danger mt-4 text-center">{error}</p>}

      {recette && (
        <div className="mt-5 p-4 border rounded bg-light">
          <h2 className="text-center">Recette Générée</h2>
          <table className="table table-bordered">
            <thead className="table-dark">
              <tr>
                <th>Nom</th>
                <th>Groupe</th>
                <th>Type</th>
                <th>Ingrédients</th>
                <th>Préparation</th>
                <th>Apports</th>
                <th>Durée (min)</th>
                <th>Prix</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{recette.nom}</td>
                <td>{recette.groupe}</td>
                <td>{recette.type}</td>
                <td>
                  <ul>
                    {recette.ingredients.map((ing, index) => (
                      <li key={index}>{ing}</li>
                    ))}
                  </ul>
                </td>
                <td>
                  <ol>
                    {recette.preparation.map((step, index) => (
                      <li key={index}>{step}</li>
                    ))}
                  </ol>
                </td>
                <td>{recette.apports}</td>
                <td>{recette.duree}</td>
                <td>{recette.prix}</td>
              </tr>
            </tbody>
          </table>
          
          <button onClick={generatePDF} className="btn btn-success mt-3">Télécharger en PDF</button>
        </div>
      )}
    </div>
  );
};

export default Recette;