import React, { useState } from 'react';
import axios from 'axios';
import Navigation from './Navigation';

const Scoring = () => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);

  // Fonction pour gérer la sélection du fichier
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  // Fonction pour envoyer le fichier au backend et récupérer les résultats
  const handleProcessFile = async () => {
    if (!file) {
      alert('Veuillez sélectionner un fichier Excel.');
      return;
    }

    const formData = new FormData();
    formData.append('excel_file', file);

    setLoading(true);

    try {
      const response = await axios.post('http://localhost:8080/processExcel', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setResults(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Erreur lors du traitement du fichier:', error);
      setLoading(false);
    }
  };

  return (
    <div>
      <Navigation />
      <input type="file" accept=".xlsx" onChange={handleFileChange} />
      <button onClick={handleProcessFile} disabled={loading}>
        {loading ? 'Traitement en cours...' : 'Lancer le traitement'}
      </button>
      {results.length > 0 && (
        <table>
          <thead>
            <tr>
              <th>Nom du compte</th>
              <th>Ville de facturation</th>
              <th>Trust Score</th>
              <th>Mots-clés correspondants</th>
            </tr>
          </thead>
          <tbody>
            {results.map((row, index) => (
              <tr key={index}>
                <td>{row[1]}</td>
                <td>{row[2]}</td>
                <td>{row[3]}</td>
                <td>{row[4]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Scoring;
