import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Searchlink from "./Searchlink";
import Evaluate from "./Evaluate";
import Filter from "./Filter";
import Searchdistance from "./Searchdistance";
import DistanceCalculator from "./Distancecalculator";
import Otherlinks from "./Otherlinks";
import Scoring from "./Scoring";
import Recette from "./Recette";
import Pappers from "./Pappers";


const App = () => {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Searchlink />} ></Route>
        <Route path="/scoring" element={<Scoring />} ></Route>
        <Route path="/linkedin" element={<Searchlink />} ></Route>
        <Route path='/otherlinks' element={<Otherlinks />}></Route>
        <Route path='/evaluate' element={<Evaluate />}></Route>
        <Route path="/filter" element={<Filter />}></Route>
        <Route path='/searchdistance' element={<Searchdistance />}></Route>
        <Route path='/distancecalculator' element={<DistanceCalculator />}></Route>
        <Route path='/recette' element={<Recette />}></Route>
        <Route path="/pappers" element={<Pappers />} ></Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App;