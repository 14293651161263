import React from "react";

const data = {
    numero: "31555826AE0158",
    section: "AE",
    prefixe: "826",
    numero_plan: "158",
    adresse: "16 PLACE SAINT PIERRE 31000 TOULOUSE",
    sources_adresse: [
        "gouv", "ventes", "proprietaires", "coproprietes", "occupants", "permis", "fonds_de_commerce"
    ],
    code_commune: "31555",
    commune: "TOULOUSE",
    code_region: "76",
    region: "OCCITANIE",
    code_departement: "31",
    departement: "HAUTE-GARONNE",
    codes_postaux: ["31300", "31500", "31200", "31400", "31000", "31100"],
    contenance: 250,
    ventes: [{
        id: "2021-478556",
        date: "2021-09-14",
        nature: "vente",
        valeur_fonciere: 979000,
        type_local: "maison",
        surface_reelle_bati: 30,
        surface_terrain: 200,
        nombre_pieces: 3,
        adresse: "16 PLACE SAINT PIERRE 31000 TOULOUSE"
    }],
    batiments: [{
        annee_construction: 1990,
        usages: "Résidentiel",
        etat: "En service",
        hauteur_moyenne: 21,
        nombre_logements: 1
    }],
};

const Pappers = () => {
    return (
        <div className="p-6 max-w-3xl mx-auto bg-white rounded-xl shadow-md space-y-4">
            <h1 className="text-xl font-bold">Informations de la Parcelle</h1>
            <p><strong>Numéro:</strong> {data.numero}</p>
            <p><strong>Adresse:</strong> {data.adresse}</p>
            <p><strong>Commune:</strong> {data.commune} ({data.code_commune})</p>
            <p><strong>Région:</strong> {data.region} ({data.code_region})</p>
            <p><strong>Département:</strong> {data.departement} ({data.code_departement})</p>
            <p><strong>Codes Postaux:</strong> {data.codes_postaux.join(", ")}</p>
            <p><strong>Contenance:</strong> {data.contenance} m²</p>
            
            <h2 className="text-lg font-semibold">Sources d'Adresse</h2>
            <ul className="list-disc pl-6">
                {data.sources_adresse.map((source, index) => (
                    <li key={index}>{source}</li>
                ))}
            </ul>
            
            <h2 className="text-lg font-semibold">Ventes</h2>
            {data.ventes.map((vente, index) => (
                <div key={index} className="p-4 bg-gray-100 rounded-md">
                    <p><strong>Date:</strong> {vente.date}</p>
                    <p><strong>Nature:</strong> {vente.nature}</p>
                    <p><strong>Valeur foncière:</strong> {vente.valeur_fonciere} €</p>
                    <p><strong>Type:</strong> {vente.type_local}</p>
                    <p><strong>Surface bâtie:</strong> {vente.surface_reelle_bati} m²</p>
                    <p><strong>Surface terrain:</strong> {vente.surface_terrain} m²</p>
                    <p><strong>Nombre de pièces:</strong> {vente.nombre_pieces}</p>
                </div>
            ))}
            
            <h2 className="text-lg font-semibold">Bâtiments</h2>
            {data.batiments.map((batiment, index) => (
                <div key={index} className="p-4 bg-gray-100 rounded-md">
                    <p><strong>Année de construction:</strong> {batiment.annee_construction}</p>
                    <p><strong>Usages:</strong> {batiment.usages}</p>
                    <p><strong>État:</strong> {batiment.etat}</p>
                    <p><strong>Hauteur moyenne:</strong> {batiment.hauteur_moyenne} m</p>
                    <p><strong>Nombre de logements:</strong> {batiment.nombre_logements}</p>
                </div>
            ))}
        </div>
    );
};

export default Pappers;
