import React, { useState } from "react";
import * as XLSX from "xlsx";
import Navigation from './Navigation';

const Searchdistance = () => {
    // ----------- Variables d'état
    const [excelData, setExcelData] = useState([]);
    const [listeDeHeaders, setListeDeHeaders] = useState([]);
    const [departAdresse, setDepartAdresse] = useState("");
    const [adresseColonne1, setAdresseColonne1] = useState("");
    const [adresseColonne2, setAdresseColonne2] = useState("");
    const [loading, setLoading] = useState(false);

    // ----------- Fonction pour charger le fichier Excel
    const handleFileUpload = (event) => {
        const fichierExcel = event.target.files[0];
        const lecteurDeFichier = new FileReader();

        lecteurDeFichier.onload = (e) => {
            const classeurExcel = new Uint8Array(e.target.result);
            const donneesBrutes = XLSX.read(classeurExcel, { type: 'array' });
            const feuilleALire = donneesBrutes.SheetNames[0];
            const feuilleATraiterIci = donneesBrutes.Sheets[feuilleALire];
            const jsonData = XLSX.utils.sheet_to_json(feuilleATraiterIci, { defval: "" });
            setExcelData(jsonData);

            // Récupérer les en-têtes des colonnes
            const nonEmptyRow = jsonData.find(row => Object.values(row).some(cell => cell !== ''));
            const tempHeaderList = nonEmptyRow ? Object.keys(nonEmptyRow) : [];
            setListeDeHeaders(tempHeaderList);
        };
        lecteurDeFichier.readAsArrayBuffer(fichierExcel);
    };

    // ----------- Fonction pour introduire un délai
    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    // ----------- Fonction pour calculer la distance avec délai
    const calculateDistances = async () => {
        if (!departAdresse || !adresseColonne1) {
            alert("Veuillez entrer une adresse de départ et sélectionner les colonnes d'adresse.");
            return;
        }

        setLoading(true);
        const results = [...excelData]; // Copie pour éviter de modifier directement l’état

        for (let i = 0; i < excelData.length; i++) {
            const ligne = excelData[i];
            const adresse1 = ligne[adresseColonne1] || ""; // Évite 'undefined'
            const adresse2 = adresseColonne2 ? (ligne[adresseColonne2] || "") : "";
            const adresseLigne = `${adresse1} ${adresse2}`.trim(); // Supprime espaces inutiles

            try {
                // Introduire un délai entre chaque requête
                await delay(500); // 500ms entre chaque requête

                const url = `https://api.axel.mg/getduration?origins=${encodeURIComponent(departAdresse)}&destinations=${encodeURIComponent(adresseLigne)}`;
                const response = await fetch(url);

                if (!response.ok) throw new Error(`Erreur HTTP: ${response.status}`);

                const data = await response.json();
                const duration = data.rows?.[0]?.elements?.[0]?.duration?.text || "N/A";

                results[i] = { ...ligne, "Distance (min)": duration };
            } catch (error) {
                console.error("Erreur lors de la requête de calcul de distance :", error);
                results[i] = { ...ligne, "Distance (min)": "Erreur" };
            }

            // Mise à jour progressive des résultats
            setExcelData([...results]);
        }

        setLoading(false);
    };

    // ----------- Fonction pour exporter les résultats
    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(excelData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Résultats");
        XLSX.writeFile(workbook, "Distances_Calculées.xlsx");
    };

    return (
        <div className="container">
            <Navigation />
            <div className="app">
                <h1>Calcul de distances</h1>

                <div className="controls">
                    <input type="file" accept=".xlsx, .xls" onChange={handleFileUpload} />

                    <input
                        type="text"
                        value={departAdresse}
                        onChange={(e) => setDepartAdresse(e.target.value)}
                        placeholder="Entrez l'adresse de départ"
                        className="text-input"
                    />

                    <select value={adresseColonne1} onChange={(e) => setAdresseColonne1(e.target.value)} className="select-input">
                        <option value="">Sélectionnez la colonne de rue</option>
                        {listeDeHeaders.map((header, index) => (
                            <option key={index} value={header}>{header}</option>
                        ))}
                    </select>

                    <select value={adresseColonne2} onChange={(e) => setAdresseColonne2(e.target.value)} className="select-input">
                        <option value="">Sélectionnez la colonne de code postal (optionnel)</option>
                        {listeDeHeaders.map((header, index) => (
                            <option key={index} value={header}>{header}</option>
                        ))}
                    </select>

                    <button onClick={calculateDistances} className="button" disabled={loading}>
                        {loading ? "Calcul en cours..." : "Calculer les distances"}
                    </button>
                    <button onClick={exportToExcel} className="button">Exporter vers Excel</button>
                </div>

                <div className="montableau">
                    <h2>Résultats de recherche</h2>
                    <table className="data-table">
                        <thead>
                            <tr>
                                {listeDeHeaders.map((head, index) => (
                                    <th key={index}>{head}</th>
                                ))}
                                <th>Distance (min)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {excelData.map((ligne, index) => (
                                <tr key={index}>
                                    {listeDeHeaders.map((head, idx) => (
                                        <td key={idx}>{ligne[head]}</td>
                                    ))}
                                    <td>{ligne["Distance (min)"] || "-"}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Searchdistance;
